import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const Card = styled(motion.div)`
  background: white;
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;

const Title = styled.h3`
  color: #2c3e50;
  margin: 0 0 1rem 0;
  font-size: 1.25rem;
`;

const MacroInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const MacroItem = styled.div`
  text-align: center;
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 8px;

  p {
    margin: 0;
    &:first-of-type {
      font-size: 0.875rem;
      color: #666;
    }
    &:last-of-type {
      font-weight: bold;
      color: #2c3e50;
    }
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
`;

const PrepTime = styled.span`
  color: #666;
  font-size: 0.875rem;
`;

const Calories = styled.span`
  font-weight: bold;
  color: #e67e22;
`;

const ViewButton = styled.button`
  background: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #2980b9;
  }
`;

const RecipeCard = ({ recipe, onClick }) => {
  return (
    <Card
      whileHover={{ y: -5 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      onClick={() => onClick(recipe)}
    >
      <Title>{recipe.name}</Title>
      
      <MacroInfo>
        <MacroItem>
          <p>Protéines</p>
          <p>{recipe.macros.protein}g</p>
        </MacroItem>
        <MacroItem>
          <p>Glucides</p>
          <p>{recipe.macros.carbs}g</p>
        </MacroItem>
        <MacroItem>
          <p>Lipides</p>
          <p>{recipe.macros.fat}g</p>
        </MacroItem>
      </MacroInfo>

      <InfoRow>
        <div>
          <PrepTime>{recipe.prepTime}</PrepTime>
          <Calories> • {recipe.calories} kcal</Calories>
        </div>
        <ViewButton onClick={(e) => {
          e.stopPropagation();
          onClick(recipe);
        }}>
          Voir la recette
        </ViewButton>
      </InfoRow>
    </Card>
  );
};

export default RecipeCard;
