import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FaTimes, FaHeart, FaShare, FaStar } from 'react-icons/fa';
import RecipeActions from './RecipeActions';
import RecipeSuggestions from './RecipeSuggestions';
import RecipeRating from './RecipeRating';
import ServingCalculator from './ServingCalculator';

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
`;

const RecipeDetailContainer = styled(motion.div)`
  background: white;
  border-radius: 20px;
  width: 100%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  margin: 20px auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  
  /* Styliser la barre de défilement */
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    background: #f8f9fa;
  }

  svg {
    width: 20px;
    height: 20px;
    color: #2c3e50;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  margin-bottom: 2rem;
  border-radius: 15px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  padding: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const Title = styled.h2`
  color: #2c3e50;
  font-size: 2rem;
  margin: 0;
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Stat = styled.div`
  margin-right: 2rem;
`;

const Label = styled.span`
  color: #666;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
`;

const Value = styled.span`
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h3`
  color: #34495e;
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const MacrosGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const MacroItem = styled.div`
  background: #f0f2f5;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
`;

const MacroLabel = styled.h4`
  margin: 0 0 0.5rem 0;
  color: #2c3e50;
`;

const MacroValue = styled.p`
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
  color: #3498db;
`;

const Instructions = styled.div`
  margin-bottom: 2rem;
`;

const Step = styled.div`
  margin-bottom: 1rem;
`;

const StepNumber = styled.span`
  color: #666;
  font-size: 1.25rem;
  margin-right: 0.5rem;
`;

const StepText = styled.span`
  color: #333;
  font-size: 1.25rem;
`;

const Benefits = styled.div`
  margin-bottom: 2rem;
`;

const Benefit = styled.div`
  margin-bottom: 0.5rem;
`;

const BenefitIcon = styled.span`
  color: #2ecc71;
  font-size: 1.25rem;
  margin-right: 0.5rem;
`;

const RecipeDetail = ({ recipe, onClose, allRecipes, onRecipeClick }) => {
  if (!recipe) return null;

  return (
    <Overlay
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <RecipeDetailContainer
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
        onClick={e => e.stopPropagation()}
      >
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>

        <ImageContainer>
          <Image src={recipe.image} alt={recipe.name} />
          <RecipeActions recipe={recipe} />
        </ImageContainer>

        <Content>
          <Header>
            <Title>{recipe.name}</Title>
            <Stats>
              <Stat>
                <Label>Temps</Label>
                <Value>{recipe.prepTime}</Value>
              </Stat>
              <Stat>
                <Label>Calories</Label>
                <Value>{recipe.calories} kcal</Value>
              </Stat>
              <Stat>
                <Label>Difficulté</Label>
                <Value>{recipe.difficulty}</Value>
              </Stat>
            </Stats>
          </Header>

          <Section>
            <SectionTitle>Macronutriments</SectionTitle>
            <MacrosGrid>
              <MacroItem>
                <MacroLabel>Protéines</MacroLabel>
                <MacroValue>{recipe.macros.protein}g</MacroValue>
              </MacroItem>
              <MacroItem>
                <MacroLabel>Glucides</MacroLabel>
                <MacroValue>{recipe.macros.carbs}g</MacroValue>
              </MacroItem>
              <MacroItem>
                <MacroLabel>Lipides</MacroLabel>
                <MacroValue>{recipe.macros.fat}g</MacroValue>
              </MacroItem>
            </MacrosGrid>
          </Section>

          <Section>
            <SectionTitle>Ingrédients</SectionTitle>
            <ServingCalculator 
              ingredients={recipe.ingredients}
              defaultServings={recipe.servings || 4}
            />
          </Section>

          <Section>
            <SectionTitle>Instructions</SectionTitle>
            <Instructions>
              {recipe.instructions.map((step, index) => (
                <Step key={index}>
                  <StepNumber>{index + 1}</StepNumber>
                  <StepText>{step}</StepText>
                </Step>
              ))}
            </Instructions>
          </Section>

          <Section>
            <SectionTitle>Bénéfices santé</SectionTitle>
            <Benefits>
              {recipe.benefits.map((benefit, index) => (
                <Benefit key={index}>
                  <BenefitIcon>✓</BenefitIcon>
                  {benefit}
                </Benefit>
              ))}
            </Benefits>
          </Section>

          <Section>
            <SectionTitle>Évaluation</SectionTitle>
            <RecipeRating recipe={recipe} />
          </Section>

          <RecipeSuggestions
            currentRecipe={recipe}
            allRecipes={allRecipes}
            onRecipeClick={onRecipeClick}
          />
        </Content>
      </RecipeDetailContainer>
    </Overlay>
  );
};

export default React.memo(RecipeDetail);
