import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { nutritionGoals } from '../data/nutritionGoals';
import { nutritionTips } from '../data/nutritionTips';

const Section = styled.section`
  padding: 4rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled(motion.div)`
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background: #eee;
  border-radius: 5px;
  margin: 0.5rem 0;
  overflow: hidden;
`;

const Progress = styled.div`
  width: ${props => props.value}%;
  height: 100%;
  background: ${props => props.color || '#4CAF50'};
  transition: width 0.3s ease;
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 1rem 0;
`;

const MetricCard = styled.div`
  background: ${props => props.highlight ? '#e8f5e9' : '#fff'};
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
`;

const TrendIndicator = styled.span`
  color: ${props => props.trend === 'up' ? '#4caf50' : props.trend === 'down' ? '#f44336' : '#757575'};
  margin-left: 0.5rem;
`;

const WeeklyChart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100px;
  padding: 1rem 0;
`;

const ChartBar = styled.div`
  width: 12%;
  background: #4caf50;
  height: ${props => props.height}%;
  border-radius: 4px 4px 0 0;
  position: relative;
  
  &:hover::after {
    content: '${props => props.value}';
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background: #333;
    color: white;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 0.8rem;
  }
`;

const MealTypeTag = styled.span`
  background: ${props => {
    switch(props.type) {
      case 'petit-dejeuner': return '#fff3e0';
      case 'dejeuner': return '#e8f5e9';
      case 'diner': return '#e3f2fd';
      case 'collation': return '#fce4ec';
      default: return '#f5f5f5';
    }
  }};
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  margin-right: 0.5rem;
`;

const NutritionTracker = () => {
  const [profile, setProfile] = useState('maintenance');
  const [dailyLog, setDailyLog] = useState({
    calories: 0,
    protein: 0,
    carbs: 0,
    fat: 0,
    fiber: 0,
    water: 0
  });

  const [weeklyStats, setWeeklyStats] = useState({
    calories: [1800, 2100, 1950, 2000, 1850, 1900, 0],
    weight: [75.5, 75.3, 75.2, 75.0, 74.8, 74.7, 74.7],
    steps: [8000, 10000, 7500, 9000, 8500, 9500, 5000]
  });

  const [mealHistory, setMealHistory] = useState([
    {
      id: 1,
      name: "Petit-déjeuner équilibré",
      type: "petit-dejeuner",
      time: "08:00",
      calories: 350,
      protein: 15,
      carbs: 45,
      fat: 12,
      mood: "energetic"
    }
  ]);

  const [healthMetrics, setHealthMetrics] = useState({
    weight: 74.7,
    bmi: 23.5,
    bodyFat: 18,
    muscleMass: 35,
    hydration: 65,
    sleepHours: 7.5,
    stressLevel: 3,
    energyLevel: 4
  });

  const goals = nutritionGoals.profiles[profile];
  const recommendations = nutritionGoals.recommendations;
  const dailyIntake = nutritionGoals.dailyIntakeRecommendations;

  // Calcul des tendances sur 7 jours
  const calculateTrend = (metric) => {
    const lastWeek = weeklyStats[metric];
    const trend = lastWeek[lastWeek.length - 1] - lastWeek[0];
    return trend > 0 ? 'up' : trend < 0 ? 'down' : 'stable';
  };

  // Calcul des moyennes hebdomadaires
  const calculateWeeklyAverage = (metric) => {
    const values = weeklyStats[metric];
    return (values.reduce((a, b) => a + b, 0) / values.length).toFixed(1);
  };

  return (
    <Section>
      <Container>
        <h2>Tableau de Bord Nutritionnel</h2>
        <Grid>
          {/* Première colonne : Objectifs et Métriques */}
          <Card>
            <h3>Profil et Objectifs</h3>
            <select value={profile} onChange={(e) => setProfile(e.target.value)}>
              <option value="maintenance">Maintien</option>
              <option value="weightLoss">Perte de poids</option>
              <option value="muscleGain">Prise de muscle</option>
            </select>

            <MetricsGrid>
              <MetricCard>
                <h4>IMC</h4>
                <div>{healthMetrics.bmi}
                  <TrendIndicator trend={calculateTrend('weight')}>
                    {calculateTrend('weight') === 'down' ? '↓' : calculateTrend('weight') === 'up' ? '↑' : '→'}
                  </TrendIndicator>
                </div>
              </MetricCard>
              <MetricCard>
                <h4>Masse musculaire</h4>
                <div>{healthMetrics.muscleMass}%</div>
              </MetricCard>
              <MetricCard>
                <h4>Hydratation</h4>
                <div>{healthMetrics.hydration}%</div>
              </MetricCard>
              <MetricCard>
                <h4>Qualité du sommeil</h4>
                <div>{healthMetrics.sleepHours}h</div>
              </MetricCard>
            </MetricsGrid>

            <h4>Progression hebdomadaire</h4>
            <WeeklyChart>
              {weeklyStats.calories.map((value, index) => (
                <ChartBar 
                  key={index}
                  height={(value / 2500) * 100}
                  value={`${value} kcal`}
                />
              ))}
            </WeeklyChart>
          </Card>

          {/* Deuxième colonne : Journal et Analyses */}
          <Card>
            <h3>Journal Alimentaire</h3>
            <div>
              <h4>Aujourd'hui</h4>
              {mealHistory.map(meal => (
                <div key={meal.id} style={{ margin: '1rem 0' }}>
                  <MealTypeTag type={meal.type}>
                    {meal.type.charAt(0).toUpperCase() + meal.type.slice(1)}
                  </MealTypeTag>
                  <strong>{meal.name}</strong>
                  <div style={{ marginTop: '0.5rem' }}>
                    <small>
                      {meal.time} • {meal.calories} kcal • 
                      P: {meal.protein}g • G: {meal.carbs}g • L: {meal.fat}g
                    </small>
                  </div>
                </div>
              ))}

              <h4>Analyse Nutritionnelle</h4>
              <MetricsGrid>
                <div>
                  <h5>Répartition des macros</h5>
                  <div>Protéines: {((dailyLog.protein * 4) / dailyLog.calories * 100).toFixed(1)}%</div>
                  <div>Glucides: {((dailyLog.carbs * 4) / dailyLog.calories * 100).toFixed(1)}%</div>
                  <div>Lipides: {((dailyLog.fat * 9) / dailyLog.calories * 100).toFixed(1)}%</div>
                </div>
                <div>
                  <h5>Qualité nutritionnelle</h5>
                  <div>Fibres: {dailyLog.fiber}g / {goals.fiber}g</div>
                  <div>Eau: {dailyLog.water}ml / {dailyIntake.water.min}ml</div>
                </div>
              </MetricsGrid>
            </div>
          </Card>

          {/* Troisième colonne : Conseils et Recommandations */}
          <Card>
            <h3>Insights & Recommandations</h3>
            
            <div style={{ marginBottom: '2rem' }}>
              <h4>Tendances</h4>
              <MetricsGrid>
                <MetricCard highlight={calculateTrend('calories') === 'down'}>
                  <h5>Calories moyennes</h5>
                  <div>{calculateWeeklyAverage('calories')} kcal/jour</div>
                </MetricCard>
                <MetricCard highlight={calculateTrend('weight') === 'down'}>
                  <h5>Évolution poids</h5>
                  <div>{(weeklyStats.weight[6] - weeklyStats.weight[0]).toFixed(1)} kg</div>
                </MetricCard>
              </MetricsGrid>
            </div>

            <div>
              <h4>Conseils personnalisés</h4>
              {nutritionTips.general.slice(0, 2).map(tip => (
                <div key={tip.id} style={{ margin: '1rem 0' }}>
                  <h5>{tip.title} {tip.icon}</h5>
                  <p>{tip.content}</p>
                </div>
              ))}
            </div>

            <div style={{ marginTop: '2rem' }}>
              <h4>Objectifs de la semaine</h4>
              <ul>
                <li>Augmenter l'apport en protéines de 10%</li>
                <li>Atteindre 2L d'eau par jour</li>
                <li>Inclure 5 portions de légumes par jour</li>
              </ul>
            </div>
          </Card>
        </Grid>
      </Container>
    </Section>
  );
};

export default NutritionTracker;
