import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import Features from './components/Features';
import RecipesSection from './components/RecipesSection';
import MealPlanner from './components/MealPlanner';
import NutritionTracker from './components/NutritionTracker';
import AffiliateSection from './components/AffiliateSection';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import RecipeList from './components/RecipeList';
import RecettesPage from './pages/Recettes';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Features />
              <RecipeList />
              <ContactForm />
            </>
          } />
          <Route path="/recettes" element={<RecettesPage />} />
          <Route path="/planificateur" element={<MealPlanner />} />
          <Route path="/suivi" element={<NutritionTracker />} />
          <Route path="/contact" element={<ContactForm />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
