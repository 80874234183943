import React, { useState } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const Section = styled.section`
  padding: 5rem 2rem;
  background-color: #f5f7fa;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const WeekGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
  
  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

const DayCard = styled.div`
  background: white;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DayHeader = styled.h3`
  margin: 0 0 1rem 0;
  color: #2c3e50;
  text-align: center;
`;

const MealSlot = styled.div`
  border: 1px dashed #ddd;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  min-height: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    border-color: #4CAF50;
    background-color: #f8f9fa;
  }
`;

const RecipeSelector = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
`;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RecipeList = styled.div`
  display: grid;
  gap: 1rem;
`;

const RecipeOption = styled(motion.div)`
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background-color: #f8f9fa;
  }
`;

const GroceryList = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
`;

const GroceryItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
  }
`;

const GenerateButton = styled(motion.button)`
  padding: 1rem 2rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  
  &:hover {
    background-color: #45a049;
  }
`;

const MealPlanner = () => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [showRecipeSelector, setShowRecipeSelector] = useState(false);
  const [weekPlan, setWeekPlan] = useState({
    lundi: { dejeuner: null, diner: null },
    mardi: { dejeuner: null, diner: null },
    mercredi: { dejeuner: null, diner: null },
    jeudi: { dejeuner: null, diner: null },
    vendredi: { dejeuner: null, diner: null },
    samedi: { dejeuner: null, diner: null },
    dimanche: { dejeuner: null, diner: null },
  });

  const handleMealSlotClick = (day, meal) => {
    setSelectedDay(day);
    setSelectedMeal(meal);
    setShowRecipeSelector(true);
  };

  const handleRecipeSelect = (recipe) => {
    setWeekPlan(prev => ({
      ...prev,
      [selectedDay]: {
        ...prev[selectedDay],
        [selectedMeal]: recipe
      }
    }));
    setShowRecipeSelector(false);
  };

  const generateGroceryList = () => {
    // Logique pour générer la liste de courses
    const ingredients = new Set();
    Object.values(weekPlan).forEach(day => {
      Object.values(day).forEach(meal => {
        if (meal && meal.ingredients) {
          meal.ingredients.forEach(ingredient => ingredients.add(ingredient));
        }
      });
    });
    return Array.from(ingredients);
  };

  return (
    <Section>
      <Container>
        <WeekGrid>
          {Object.entries(weekPlan).map(([day, meals]) => (
            <DayCard key={day}>
              <DayHeader>{day.charAt(0).toUpperCase() + day.slice(1)}</DayHeader>
              <MealSlot onClick={() => handleMealSlotClick(day, 'dejeuner')}>
                {meals.dejeuner ? meals.dejeuner.title : 'Ajouter un déjeuner'}
              </MealSlot>
              <MealSlot onClick={() => handleMealSlotClick(day, 'diner')}>
                {meals.diner ? meals.diner.title : 'Ajouter un dîner'}
              </MealSlot>
            </DayCard>
          ))}
        </WeekGrid>

        <GenerateButton
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            const groceryList = generateGroceryList();
            // Afficher la liste de courses
          }}
        >
          Générer la liste de courses
        </GenerateButton>

        {showRecipeSelector && (
          <Overlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowRecipeSelector(false)}
          >
            <RecipeSelector
              onClick={e => e.stopPropagation()}
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
            >
              <h3>Sélectionner une recette</h3>
              <RecipeList>
                {/* Liste des recettes disponibles */}
                <RecipeOption
                  whileHover={{ scale: 1.02 }}
                  onClick={() => handleRecipeSelect({
                    title: 'Salade César',
                    ingredients: ['Laitue', 'Poulet', 'Parmesan', 'Croûtons']
                  })}
                >
                  Salade César
                </RecipeOption>
                {/* Ajoutez plus de recettes ici */}
              </RecipeList>
            </RecipeSelector>
          </Overlay>
        )}
      </Container>
    </Section>
  );
};

export default MealPlanner;
