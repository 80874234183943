import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const FeaturesSection = styled.section`
  padding: 5rem 2rem;
  background-color: #fff;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
`;

const FeatureCard = styled(motion.div)`
  padding: 2rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const Icon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #4CAF50;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  color: #666;
  line-height: 1.6;
`;

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
    },
  },
};

const Features = () => {
  const features = [
    {
      icon: "🥗",
      title: "Recettes Saines",
      description: "Découvrez notre collection de recettes nutritives et équilibrées, soigneusement élaborées pour allier santé et plaisir gustatif."
    },
    {
      icon: "📅",
      title: "Planificateur de Repas",
      description: "Organisez vos repas de la semaine en fonction de vos préférences et besoins nutritionnels avec notre outil de planification intuitif."
    },
    {
      icon: "📊",
      title: "Suivi Alimentaire",
      description: "Suivez votre consommation alimentaire et vos objectifs de bien-être grâce à notre tableau de bord personnalisé."
    }
  ];

  return (
    <FeaturesSection>
      <Container>
        <FeatureGrid>
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <Icon>{feature.icon}</Icon>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </FeatureCard>
          ))}
        </FeatureGrid>
      </Container>
    </FeaturesSection>
  );
};

export default Features;
