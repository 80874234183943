import React, { useState } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const SearchContainer = styled.div`
  margin-bottom: 2rem;
`;

const SearchBar = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
  }
`;

const CategoryList = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
`;

const CategoryButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 20px;
  background: ${props => props.active ? '#3498db' : '#f0f0f0'};
  color: ${props => props.active ? 'white' : '#333'};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.active ? '#2980b9' : '#e0e0e0'};
  }
`;

const FiltersContainer = styled(motion.div)`
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 1.5rem;
`;

const FilterGroup = styled.div`
  margin-bottom: 1rem;

  h4 {
    margin: 0 0 0.5rem 0;
    color: #2c3e50;
  }
`;

const FilterSelect = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  font-size: 0.9rem;
`;

const FilterRange = styled.input`
  width: 100%;
  margin: 0.5rem 0;
`;

const ToggleFiltersButton = styled.button`
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0;
  text-decoration: underline;
  margin-top: 1rem;

  &:hover {
    color: #2980b9;
  }
`;

const RecipeSearch = ({
  categories,
  activeCategory,
  onCategoryChange,
  searchQuery,
  onSearchChange,
  filters,
  onFiltersChange
}) => {
  const [showFilters, setShowFilters] = useState(false);

  const handleFilterChange = (key, value) => {
    onFiltersChange({
      ...filters,
      [key]: value
    });
  };

  return (
    <SearchContainer>
      <SearchBar>
        <SearchInput
          type="text"
          placeholder="Rechercher une recette..."
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </SearchBar>

      <CategoryList>
        {categories.map((category) => (
          <CategoryButton
            key={category}
            active={category === activeCategory}
            onClick={() => onCategoryChange(category)}
          >
            {category === 'all' ? 'Toutes les recettes' : category}
          </CategoryButton>
        ))}
      </CategoryList>

      <ToggleFiltersButton onClick={() => setShowFilters(!showFilters)}>
        {showFilters ? 'Masquer les filtres' : 'Afficher les filtres'}
      </ToggleFiltersButton>

      {showFilters && (
        <FiltersContainer
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
        >
          <FilterGroup>
            <h4>Difficulté</h4>
            <FilterSelect
              value={filters.difficulty || ''}
              onChange={(e) => handleFilterChange('difficulty', e.target.value || null)}
            >
              <option value="">Toutes</option>
              <option value="Facile">Facile</option>
              <option value="Moyen">Moyen</option>
              <option value="Difficile">Difficile</option>
            </FilterSelect>
          </FilterGroup>

          <FilterGroup>
            <h4>Calories max: {filters.maxCalories} kcal</h4>
            <FilterRange
              type="range"
              min="100"
              max="1000"
              step="50"
              value={filters.maxCalories}
              onChange={(e) => handleFilterChange('maxCalories', parseInt(e.target.value))}
            />
          </FilterGroup>

          <FilterGroup>
            <h4>Temps de préparation max: {filters.maxPrepTime} min</h4>
            <FilterRange
              type="range"
              min="10"
              max="120"
              step="5"
              value={filters.maxPrepTime}
              onChange={(e) => handleFilterChange('maxPrepTime', parseInt(e.target.value))}
            />
          </FilterGroup>
        </FiltersContainer>
      )}
    </SearchContainer>
  );
};

export default RecipeSearch;
