import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import RecipeCard from './RecipeCard';
import RecipeDetail from './RecipeDetail';
import RecipeSearch from './RecipeSearch';
import recipesData from '../data/recipes.json';

// Fusionner les recettes des deux structures
const getAllRecipes = () => {
  const categorizedRecipes = [];
  // Ajouter les recettes de la structure catégorisée
  Object.entries(recipesData.categories).forEach(([mainCategory, subcategories]) => {
    Object.entries(subcategories).forEach(([subCategory, recipes]) => {
      recipes.forEach(recipe => {
        categorizedRecipes.push({
          ...recipe,
          id: recipe.name.toLowerCase().replace(/\s+/g, '-'),
          category: mainCategory,
          subCategory
        });
      });
    });
  });
  
  // Ajouter les recettes de la liste plate
  const flatRecipes = recipesData.recipes || [];
  
  // Combiner les deux ensembles de recettes
  return [...categorizedRecipes, ...flatRecipes];
};

const Container = styled(motion.div)`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;

const LoadingOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LoadingSpinner = styled(motion.div)`
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
`;

const Grid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const CategoryTitle = styled(motion.h2)`
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  text-align: center;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: #3498db;
    border-radius: 2px;
  }
`;

const NoResults = styled(motion.div)`
  text-align: center;
  padding: 3rem;
  color: #666;
  font-size: 1.2rem;
`;

const ErrorMessage = styled(motion.div)`
  background: #ff7675;
  color: white;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
`;

const RecipeList = () => {
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [activeCategory, setActiveCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    difficulty: null,
    maxCalories: 1000,
    maxPrepTime: 120
  });

  // Mémoriser toutes les recettes
  const allRecipes = useMemo(() => {
    try {
      return getAllRecipes();
    } catch (error) {
      console.error('Error processing recipes:', error);
      setError('Erreur lors du chargement des recettes');
      return [];
    }
  }, []);

  // Obtenir les catégories uniques
  const categories = useMemo(() => {
    const uniqueCategories = new Set(allRecipes.map(recipe => {
      // Convertir la première lettre en majuscule
      const category = recipe.category.charAt(0).toUpperCase() + recipe.category.slice(1);
      return category;
    }));
    return ['Toutes les recettes', ...Array.from(uniqueCategories)];
  }, [allRecipes]);

  // Filtrer les recettes
  const filteredRecipes = useMemo(() => {
    return allRecipes.filter(recipe => {
      // Filtre par catégorie
      if (activeCategory !== 'Toutes les recettes') {
        const recipeCategory = recipe.category.charAt(0).toUpperCase() + recipe.category.slice(1);
        if (recipeCategory !== activeCategory) {
          return false;
        }
      }

      // Filtre par recherche
      if (searchQuery) {
        const searchLower = searchQuery.toLowerCase();
        const nameMatch = recipe.name.toLowerCase().includes(searchLower);
        const descriptionMatch = recipe.description?.toLowerCase().includes(searchLower);
        const ingredientsMatch = recipe.ingredients.some(ing => 
          (typeof ing === 'string' ? ing : ing.name).toLowerCase().includes(searchLower)
        );
        if (!nameMatch && !descriptionMatch && !ingredientsMatch) {
          return false;
        }
      }

      // Filtre par difficulté
      if (filters.difficulty && recipe.difficulty.toLowerCase() !== filters.difficulty.toLowerCase()) {
        return false;
      }

      // Filtre par calories
      if (filters.maxCalories && recipe.calories > filters.maxCalories) {
        return false;
      }

      // Filtre par temps de préparation
      if (filters.maxPrepTime) {
        const prepTimeNumber = parseInt(recipe.prepTime);
        if (prepTimeNumber > filters.maxPrepTime) {
          return false;
        }
      }

      return true;
    });
  }, [allRecipes, activeCategory, searchQuery, filters]);

  // Gestionnaire de recette optimisé
  const handleRecipeClick = useCallback((recipe) => {
    setSelectedRecipe(recipe);
  }, []);

  // Gestionnaire de fermeture optimisé
  const handleCloseDetail = useCallback(() => {
    setSelectedRecipe(null);
  }, []);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <RecipeSearch
        categories={categories}
        activeCategory={activeCategory}
        onCategoryChange={setActiveCategory}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        filters={filters}
        onFiltersChange={setFilters}
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <Grid>
        <AnimatePresence>
          {filteredRecipes.map((recipe) => (
            <motion.div
              key={recipe.id}
              variants={itemVariants}
              layout
            >
              <RecipeCard
                recipe={recipe}
                onClick={handleRecipeClick}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </Grid>

      <AnimatePresence>
        {selectedRecipe && (
          <RecipeDetail
            recipe={selectedRecipe}
            onClose={() => setSelectedRecipe(null)}
            allRecipes={allRecipes}
          />
        )}
      </AnimatePresence>

      {loading && (
        <LoadingOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <LoadingSpinner
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          />
        </LoadingOverlay>
      )}
    </Container>
  );
};

export default React.memo(RecipeList);
