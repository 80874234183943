import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const Section = styled.section`
  padding: 5rem 2rem;
  background-color: #f5f7fa;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 3rem;
`;

const AffiliateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const AffiliateCard = styled(motion.div)`
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const AffiliateImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const AffiliateContent = styled.div`
  padding: 1.5rem;
`;

const AffiliateTitle = styled.h3`
  color: #2c3e50;
  margin-bottom: 1rem;
`;

const AffiliateDescription = styled.p`
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.6;
`;

const AffiliateButton = styled(motion.a)`
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #45a049;
  }
`;

const AffiliateSection = () => {
  const [affiliates, setAffiliates] = useState([]);

  useEffect(() => {
    fetch('/data/affiliates.json')
      .then(response => response.json())
      .then(data => setAffiliates(data.affiliates))
      .catch(error => console.error('Error loading affiliate data:', error));
  }, []);

  return (
    <Section>
      <Container>
        <Title>Nos Recommandations</Title>
        <AffiliateGrid>
          {affiliates.map(affiliate => (
            <AffiliateCard
              key={affiliate.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <AffiliateImage
                src={affiliate.imageUrl}
                alt={affiliate.title}
              />
              <AffiliateContent>
                <AffiliateTitle>{affiliate.title}</AffiliateTitle>
                <AffiliateDescription>
                  {affiliate.description}
                </AffiliateDescription>
                <AffiliateButton
                  href={affiliate.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {affiliate.cta}
                </AffiliateButton>
              </AffiliateContent>
            </AffiliateCard>
          ))}
        </AffiliateGrid>
      </Container>
    </Section>
  );
};

export default AffiliateSection;
