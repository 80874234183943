import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const SuggestionsContainer = styled.div`
  margin-top: 2rem;
`;

const SectionTitle = styled.h3`
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
`;

const SuggestionCard = styled(motion.div)`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  cursor: pointer;
  position: relative;
`;

const ImageContainer = styled.div`
  position: relative;
  padding-top: 60%;
  background: #f8f9fa;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  padding: 1rem;
`;

const Title = styled.h4`
  margin: 0 0 0.5rem 0;
  color: #2c3e50;
  font-size: 1.1rem;
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 0.875rem;
`;

const Tag = styled.span`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(255,255,255,0.9);
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.75rem;
  color: #2c3e50;
`;

const getSimilarRecipes = (currentRecipe, allRecipes, limit = 3) => {
  if (!currentRecipe || !allRecipes || !Array.isArray(allRecipes)) {
    return [];
  }

  // Calculer un score de similarité basé sur plusieurs critères
  const calculateSimilarity = (recipe1, recipe2) => {
    let score = 0;
    
    // Même catégorie
    if (recipe1.category === recipe2.category) score += 3;
    
    // Calories similaires (±100 kcal)
    if (Math.abs(recipe1.calories - recipe2.calories) <= 100) score += 2;
    
    // Ingrédients communs
    const commonIngredients = recipe1.ingredients.filter(ing1 =>
      recipe2.ingredients.some(ing2 => ing2.name === ing1.name)
    );
    score += commonIngredients.length;
    
    // Temps de préparation similaire (±10 min)
    const prep1 = parseInt(recipe1.prepTime);
    const prep2 = parseInt(recipe2.prepTime);
    if (Math.abs(prep1 - prep2) <= 10) score += 2;
    
    return score;
  };

  // Filtrer la recette actuelle et trier par score de similarité
  return allRecipes
    .filter(recipe => recipe.name !== currentRecipe.name)
    .map(recipe => ({
      ...recipe,
      similarity: calculateSimilarity(currentRecipe, recipe)
    }))
    .sort((a, b) => b.similarity - a.similarity)
    .slice(0, limit);
};

const RecipeSuggestions = ({ currentRecipe, allRecipes, onRecipeClick }) => {
  const similarRecipes = React.useMemo(() => {
    return getSimilarRecipes(currentRecipe, allRecipes);
  }, [currentRecipe, allRecipes]);

  if (!similarRecipes || similarRecipes.length === 0) {
    return null;
  }

  return (
    <SuggestionsContainer>
      <SectionTitle>Recettes similaires</SectionTitle>
      <Grid>
        {similarRecipes.map((recipe, index) => (
          <SuggestionCard
            key={`${recipe.name}-${index}`}
            onClick={() => onRecipeClick(recipe)}
            whileHover={{ y: -5 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <ImageContainer>
              {recipe.image && <img src={recipe.image} alt={recipe.name} />}
              <Tag>{recipe.difficulty}</Tag>
            </ImageContainer>
            <Content>
              <Title>{recipe.name}</Title>
              <Stats>
                <span>{recipe.prepTime}</span>
                <span>{recipe.calories} kcal</span>
              </Stats>
            </Content>
          </SuggestionCard>
        ))}
      </Grid>
    </SuggestionsContainer>
  );
};

export default React.memo(RecipeSuggestions);
