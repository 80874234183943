import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, keywords, image, url }) => {
  const siteName = 'Bien Manger Vivre Mieux';
  const defaultDescription = 'Votre ressource incontournable pour adopter une alimentation saine et équilibrée. Découvrez des recettes nutritives, des conseils d\'experts et des guides pratiques.';
  const defaultKeywords = 'alimentation saine, recettes santé, bien-être, nutrition, cuisine équilibrée, mode de vie sain';
  const defaultImage = '/images/og-image.jpg';
  const siteUrl = 'https://bienmanger-vivremieux.fr';

  const metaTitle = title ? `${title} | ${siteName}` : siteName;
  const metaDescription = description || defaultDescription;
  const metaKeywords = keywords || defaultKeywords;
  const metaImage = image || defaultImage;
  const metaUrl = url ? `${siteUrl}${url}` : siteUrl;

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={`${siteUrl}${metaImage}`} />
      <meta property="og:site_name" content={siteName} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={metaUrl} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={`${siteUrl}${metaImage}`} />

      {/* Autres métadonnées importantes */}
      <meta name="robots" content="index, follow" />
      <meta name="language" content="French" />
      <link rel="canonical" href={metaUrl} />

      {/* Métadonnées structurées pour Google */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": siteName,
          "url": siteUrl,
          "description": defaultDescription,
          "potentialAction": {
            "@type": "SearchAction",
            "target": `${siteUrl}/recherche?q={search_term_string}`,
            "query-input": "required name=search_term_string"
          }
        })}
      </script>
    </Helmet>
  );
};

export default Meta;
