import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { recipes } from '../data/recipes';

const Section = styled.section`
  padding: 5rem 2rem;
  background-color: #fff;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FilterSection = styled.div`
  margin-bottom: 2rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
  min-width: 200px;
`;

const RecipeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const RecipeCard = styled(motion.div)`
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const RecipeImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const RecipeContent = styled.div`
  padding: 1.5rem;
`;

const RecipeTitle = styled.h3`
  margin: 0 0 1rem 0;
  color: #2c3e50;
`;

const RecipeInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: #666;
`;

const RecipeDescription = styled.p`
  color: #666;
  margin-bottom: 1rem;
`;

const RecipeButton = styled(motion.button)`
  width: 100%;
  padding: 0.8rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background-color: #45a049;
  }
`;

const RecipesSection = () => {
  const [filters, setFilters] = useState({
    mealType: 'all',
    diet: 'all',
    difficulty: 'all'
  });

  const filteredRecipes = recipes.filter(recipe => {
    return (filters.mealType === 'all' || recipe.mealType === filters.mealType) &&
           (filters.diet === 'all' || recipe.diet === filters.diet) &&
           (filters.difficulty === 'all' || recipe.difficulty === filters.difficulty);
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Section>
      <Container>
        <FilterSection>
          <FilterGroup>
            <label>Type de repas</label>
            <Select name="mealType" value={filters.mealType} onChange={handleFilterChange}>
              <option value="all">Tous</option>
              <option value="petit-dejeuner">Petit-déjeuner</option>
              <option value="dejeuner">Déjeuner</option>
              <option value="diner">Dîner</option>
              <option value="collation">Collation</option>
            </Select>
          </FilterGroup>

          <FilterGroup>
            <label>Régime alimentaire</label>
            <Select name="diet" value={filters.diet} onChange={handleFilterChange}>
              <option value="all">Tous</option>
              <option value="vegetarien">Végétarien</option>
              <option value="vegan">Vegan</option>
              <option value="sans-gluten">Sans gluten</option>
            </Select>
          </FilterGroup>

          <FilterGroup>
            <label>Difficulté</label>
            <Select name="difficulty" value={filters.difficulty} onChange={handleFilterChange}>
              <option value="all">Toutes</option>
              <option value="facile">Facile</option>
              <option value="moyen">Moyen</option>
              <option value="difficile">Difficile</option>
            </Select>
          </FilterGroup>
        </FilterSection>

        <RecipeGrid>
          {filteredRecipes.map(recipe => (
            <RecipeCard
              key={recipe.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <RecipeImage src={recipe.image} alt={recipe.title} />
              <RecipeContent>
                <RecipeTitle>{recipe.title}</RecipeTitle>
                <RecipeInfo>
                  <span>{recipe.duration}</span>
                  <span>{recipe.calories} kcal</span>
                </RecipeInfo>
                <RecipeDescription>
                  {recipe.ingredients.length} ingrédients • {recipe.difficulty} • {recipe.servings} portions
                </RecipeDescription>
                <RecipeButton
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Voir la recette
                </RecipeButton>
              </RecipeContent>
            </RecipeCard>
          ))}
        </RecipeGrid>
      </Container>
    </Section>
  );
};

export default RecipesSection;
