import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import recipesData from '../data/recipes.json';
import RecipeCard from '../components/RecipeCard';
import RecipeDetail from '../components/RecipeDetail';
import Meta from '../components/Meta';

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  color: #666;
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  justify-content: center;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  border: 2px solid #3498db;
  border-radius: 25px;
  background: ${props => props.active ? '#3498db' : 'transparent'};
  color: ${props => props.active ? 'white' : '#3498db'};
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background: #3498db;
    color: white;
  }
`;

const SearchContainer = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
`;

const SearchInput = styled.input`
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
  }
`;

const RecipesGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
`;

// Fonction pour obtenir toutes les recettes
const getAllRecipes = () => {
  const categorizedRecipes = [];
  // Ajouter les recettes de la structure catégorisée
  Object.entries(recipesData.categories).forEach(([mainCategory, subcategories]) => {
    Object.entries(subcategories).forEach(([subCategory, recipes]) => {
      recipes.forEach(recipe => {
        categorizedRecipes.push({
          ...recipe,
          id: recipe.name.toLowerCase().replace(/\s+/g, '-'),
          category: mainCategory,
          subCategory
        });
      });
    });
  });
  
  // Ajouter les recettes de la liste plate
  const flatRecipes = recipesData.recipes || [];
  
  // Combiner les deux ensembles de recettes
  return [...categorizedRecipes, ...flatRecipes];
};

const RecettesPage = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  // Récupérer toutes les recettes
  const allRecipes = useMemo(() => getAllRecipes(), []);

  // Obtenir les catégories uniques
  const categories = useMemo(() => {
    const uniqueCategories = new Set(allRecipes.map(recipe => {
      const category = recipe.category.charAt(0).toUpperCase() + recipe.category.slice(1);
      return category;
    }));
    return ['Toutes les recettes', ...Array.from(uniqueCategories)];
  }, [allRecipes]);

  // Créer la description meta en fonction des recettes disponibles
  const metaDescription = useMemo(() => {
    const totalRecipes = allRecipes.length;
    const categoryList = Array.from(new Set(allRecipes.map(r => r.category)))
      .map(c => c.charAt(0).toUpperCase() + c.slice(1))
      .join(', ');
    return `Découvrez notre collection de ${totalRecipes} recettes santé. Des recettes pour tous les moments de la journée : ${categoryList}. Des plats équilibrés, savoureux et faciles à préparer.`;
  }, [allRecipes]);

  // Filtrer les recettes
  const filteredRecipes = useMemo(() => {
    return allRecipes.filter(recipe => {
      // Filtre par catégorie
      if (selectedCategory !== 'all') {
        const recipeCategory = recipe.category.charAt(0).toUpperCase() + recipe.category.slice(1);
        if (selectedCategory !== 'Toutes les recettes' && recipeCategory !== selectedCategory) {
          return false;
        }
      }

      // Filtre par recherche
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        const nameMatch = recipe.name.toLowerCase().includes(searchLower);
        const descriptionMatch = recipe.description?.toLowerCase().includes(searchLower);
        const ingredientsMatch = recipe.ingredients.some(ing => 
          (typeof ing === 'string' ? ing : ing.name).toLowerCase().includes(searchLower)
        );
        return nameMatch || descriptionMatch || ingredientsMatch;
      }

      return true;
    });
  }, [allRecipes, selectedCategory, searchTerm]);

  return (
    <PageContainer>
      <Meta
        title="Recettes Santé et Équilibrées"
        description={metaDescription}
        keywords="recettes santé, cuisine équilibrée, recettes faciles, alimentation saine, plats équilibrés, recettes nutritives"
        url="/recettes"
      />

      <Header>
        <Title>Nos Recettes Santé</Title>
        <Description>
          Découvrez notre collection de recettes saines et délicieuses, 
          soigneusement élaborées pour allier plaisir gustatif et bien-être.
        </Description>
      </Header>

      <SearchContainer>
        <SearchInput
          type="text"
          placeholder="Rechercher une recette..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchContainer>

      <FiltersContainer>
        {categories.map(category => (
          <FilterButton
            key={category}
            active={category === selectedCategory}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </FilterButton>
        ))}
      </FiltersContainer>

      <RecipesGrid
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {filteredRecipes.map((recipe, index) => (
          <RecipeCard
            key={recipe.id || index}
            recipe={recipe}
            onClick={() => setSelectedRecipe(recipe)}
          />
        ))}
      </RecipesGrid>

      {selectedRecipe && (
        <RecipeDetail
          recipe={selectedRecipe}
          onClose={() => setSelectedRecipe(null)}
          allRecipes={allRecipes}
        />
      )}
    </PageContainer>
  );
};

export default RecettesPage;
