import React, { useState } from 'react';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';
import { FaHeart, FaShare, FaPrint, FaBookmark, FaRegHeart } from 'react-icons/fa';

const ActionsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
`;

const ActionButton = styled(motion.button)`
  background: ${props => props.active ? '#e74c3c' : 'white'};
  color: ${props => props.active ? 'white' : '#2c3e50'};
  border: 2px solid ${props => props.active ? '#e74c3c' : '#e0e0e0'};
  padding: 0.5rem 1rem;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }

  svg {
    font-size: 1.1rem;
  }
`;

const ShareModal = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  z-index: 1000;
  width: 90%;
  max-width: 400px;
`;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  z-index: 999;
`;

const ShareOption = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  margin: 0.5rem 0;
  border: none;
  background: #f8f9fa;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background: #e9ecef;
  }
`;

const PrintPreview = styled.div`
  @media print {
    padding: 2rem;
    
    .no-print {
      display: none;
    }
  }
`;

const Notification = styled(motion.div)`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: #2ecc71;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
`;

const RecipeActions = ({ recipe }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [notification, setNotification] = useState(null);

  const handleFavorite = () => {
    setIsFavorite(!isFavorite);
    showNotification(isFavorite ? 'Retiré des favoris' : 'Ajouté aux favoris');
    // TODO: Intégrer avec le stockage local ou une API
  };

  const handleBookmark = () => {
    setIsBookmarked(!isBookmarked);
    showNotification(isBookmarked ? 'Retiré des signets' : 'Ajouté aux signets');
    // TODO: Intégrer avec le stockage local ou une API
  };

  const handleShare = () => {
    setShowShareModal(true);
  };

  const handlePrint = () => {
    window.print();
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(null), 3000);
  };

  const shareOptions = [
    {
      name: 'Copier le lien',
      action: () => {
        navigator.clipboard.writeText(window.location.href);
        setShowShareModal(false);
        showNotification('Lien copié !');
      }
    },
    {
      name: 'Partager sur WhatsApp',
      action: () => {
        window.open(`https://wa.me/?text=${encodeURIComponent('Découvre cette super recette : ' + recipe.name + ' - ' + window.location.href)}`);
      }
    },
    {
      name: 'Partager sur Facebook',
      action: () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`);
      }
    },
    {
      name: 'Partager par email',
      action: () => {
        window.location.href = `mailto:?subject=Découvre cette recette : ${recipe.name}&body=Voici une super recette que j'ai trouvée : ${window.location.href}`;
      }
    }
  ];

  return (
    <>
      <ActionsContainer>
        <ActionButton
          whileTap={{ scale: 0.95 }}
          active={isFavorite}
          onClick={handleFavorite}
        >
          {isFavorite ? <FaHeart /> : <FaRegHeart />}
          {isFavorite ? 'Favori' : 'Ajouter aux favoris'}
        </ActionButton>

        <ActionButton
          whileTap={{ scale: 0.95 }}
          active={isBookmarked}
          onClick={handleBookmark}
        >
          <FaBookmark />
          {isBookmarked ? 'Enregistré' : 'Sauvegarder'}
        </ActionButton>

        <ActionButton
          whileTap={{ scale: 0.95 }}
          onClick={handleShare}
        >
          <FaShare />
          Partager
        </ActionButton>

        <ActionButton
          whileTap={{ scale: 0.95 }}
          onClick={handlePrint}
          className="no-print"
        >
          <FaPrint />
          Imprimer
        </ActionButton>
      </ActionsContainer>

      <AnimatePresence>
        {showShareModal && (
          <>
            <Overlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setShowShareModal(false)}
            />
            <ShareModal
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
            >
              <h3>Partager la recette</h3>
              {shareOptions.map((option, index) => (
                <ShareOption
                  key={index}
                  onClick={option.action}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  {option.name}
                </ShareOption>
              ))}
            </ShareModal>
          </>
        )}

        {notification && (
          <Notification
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
          >
            {notification}
          </Notification>
        )}
      </AnimatePresence>
    </>
  );
};

export default RecipeActions;
