export const nutritionGoals = {
  default: {
    calories: 2000,
    protein: 75,
    carbs: 250,
    fat: 65,
    fiber: 25
  },
  profiles: {
    weightLoss: {
      calories: 1600,
      protein: 90,
      carbs: 180,
      fat: 50,
      fiber: 30
    },
    muscleGain: {
      calories: 2500,
      protein: 120,
      carbs: 300,
      fat: 80,
      fiber: 35
    },
    maintenance: {
      calories: 2000,
      protein: 75,
      carbs: 250,
      fat: 65,
      fiber: 25
    }
  },
  recommendations: {
    breakfast: {
      calories: { min: 300, max: 400 },
      protein: { min: 15, max: 25 },
      carbs: { min: 30, max: 45 },
      fat: { min: 10, max: 15 }
    },
    lunch: {
      calories: { min: 500, max: 600 },
      protein: { min: 25, max: 35 },
      carbs: { min: 60, max: 75 },
      fat: { min: 15, max: 20 }
    },
    dinner: {
      calories: { min: 400, max: 500 },
      protein: { min: 20, max: 30 },
      carbs: { min: 45, max: 60 },
      fat: { min: 12, max: 18 }
    },
    snacks: {
      calories: { min: 100, max: 200 },
      protein: { min: 5, max: 10 },
      carbs: { min: 15, max: 25 },
      fat: { min: 5, max: 8 }
    }
  },
  dailyIntakeRecommendations: {
    water: {
      min: 2000, // ml
      max: 3000  // ml
    },
    fruits: {
      min: 2,    // portions
      max: 4     // portions
    },
    vegetables: {
      min: 3,    // portions
      max: 5     // portions
    }
  }
};
