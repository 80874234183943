import React, { useState } from 'react';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';
import { FaStar, FaRegStar, FaComment, FaThumbsUp } from 'react-icons/fa';

const RatingContainer = styled.div`
  margin: 2rem 0;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 12px;
`;

const RatingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const RatingTitle = styled.h3`
  margin: 0;
  color: #2c3e50;
  font-size: 1.25rem;
`;

const StarContainer = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const StarButton = styled(motion.button)`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: ${props => props.filled ? '#f1c40f' : '#bdc3c7'};
  font-size: 1.5rem;
  
  &:hover {
    color: #f1c40f;
  }
`;

const RatingSummary = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
`;

const AverageRating = styled.div`
  text-align: center;

  h4 {
    margin: 0;
    font-size: 2.5rem;
    color: #2c3e50;
  }

  p {
    margin: 0.5rem 0 0 0;
    color: #666;
    font-size: 0.875rem;
  }
`;

const RatingBars = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const RatingBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.875rem;
  color: #666;

  span {
    min-width: 2rem;
  }
`;

const ProgressBar = styled.div`
  flex: 1;
  height: 8px;
  background: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;

  div {
    height: 100%;
    background: #f1c40f;
    width: ${props => props.value}%;
    transition: width 0.3s ease;
  }
`;

const CommentSection = styled.div`
  margin-top: 2rem;
`;

const CommentForm = styled.form`
  margin-bottom: 2rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  resize: vertical;
  min-height: 100px;
  margin-bottom: 1rem;
  font-family: inherit;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
  }
`;

const SubmitButton = styled(motion.button)`
  background: #3498db;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:disabled {
    background: #bdc3c7;
    cursor: not-allowed;
  }
`;

const CommentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const CommentCard = styled(motion.div)`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
`;

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const CommentAuthor = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }

  span {
    font-weight: 600;
    color: #2c3e50;
  }
`;

const CommentDate = styled.span`
  color: #666;
  font-size: 0.875rem;
`;

const CommentContent = styled.p`
  margin: 0;
  color: #2c3e50;
  line-height: 1.6;
`;

const CommentActions = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e0e0e0;
`;

const CommentAction = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 0.875rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    color: #3498db;
    background: rgba(52, 152, 219, 0.1);
  }
`;

const RecipeRating = ({ recipe }) => {
  const [userRating, setUserRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([
    {
      id: 1,
      author: "Sophie",
      avatar: "https://i.pravatar.cc/150?img=1",
      date: "Il y a 2 jours",
      content: "Excellente recette ! J'ai ajouté un peu plus d'épices et c'était parfait.",
      likes: 12
    },
    {
      id: 2,
      author: "Marc",
      avatar: "https://i.pravatar.cc/150?img=2",
      date: "Il y a 5 jours",
      content: "Simple à réaliser et délicieux. Toute la famille a adoré !",
      likes: 8
    }
  ]);

  const ratings = {
    5: 45,
    4: 30,
    3: 15,
    2: 7,
    1: 3
  };

  const averageRating = 4.1;
  const totalRatings = 127;

  const handleRating = (rating) => {
    setUserRating(rating);
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (!comment.trim()) return;

    const newComment = {
      id: comments.length + 1,
      author: "Vous",
      avatar: "https://i.pravatar.cc/150?img=3",
      date: "À l'instant",
      content: comment,
      likes: 0
    };

    setComments([newComment, ...comments]);
    setComment('');
  };

  const handleLike = (commentId) => {
    setComments(comments.map(comment => 
      comment.id === commentId 
        ? { ...comment, likes: comment.likes + 1 }
        : comment
    ));
  };

  return (
    <RatingContainer>
      <RatingHeader>
        <RatingTitle>Avis et commentaires</RatingTitle>
        <StarContainer>
          {[1, 2, 3, 4, 5].map((star) => (
            <StarButton
              key={star}
              filled={star <= (hoverRating || userRating)}
              onMouseEnter={() => setHoverRating(star)}
              onMouseLeave={() => setHoverRating(0)}
              onClick={() => handleRating(star)}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
            >
              {star <= (hoverRating || userRating) ? <FaStar /> : <FaRegStar />}
            </StarButton>
          ))}
        </StarContainer>
      </RatingHeader>

      <RatingSummary>
        <AverageRating>
          <h4>{averageRating}</h4>
          <p>{totalRatings} avis</p>
        </AverageRating>
        <RatingBars>
          {Object.entries(ratings).reverse().map(([rating, percentage]) => (
            <RatingBar key={rating}>
              <span>{rating}★</span>
              <ProgressBar value={percentage}>
                <div />
              </ProgressBar>
              <span>{percentage}%</span>
            </RatingBar>
          ))}
        </RatingBars>
      </RatingSummary>

      <CommentSection>
        <CommentForm onSubmit={handleCommentSubmit}>
          <TextArea
            placeholder="Partagez votre expérience..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <SubmitButton
            type="submit"
            disabled={!comment.trim()}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <FaComment />
            Publier
          </SubmitButton>
        </CommentForm>

        <CommentList>
          <AnimatePresence>
            {comments.map((comment) => (
              <CommentCard
                key={comment.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <CommentHeader>
                  <CommentAuthor>
                    <img src={comment.avatar} alt={comment.author} />
                    <span>{comment.author}</span>
                  </CommentAuthor>
                  <CommentDate>{comment.date}</CommentDate>
                </CommentHeader>
                <CommentContent>{comment.content}</CommentContent>
                <CommentActions>
                  <CommentAction onClick={() => handleLike(comment.id)}>
                    <FaThumbsUp />
                    {comment.likes} J'aime
                  </CommentAction>
                </CommentActions>
              </CommentCard>
            ))}
          </AnimatePresence>
        </CommentList>
      </CommentSection>
    </RatingContainer>
  );
};

export default RecipeRating;
