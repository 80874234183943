import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const Nav = styled.nav`
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1rem 2rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

const NavContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  color: #4CAF50;
  text-decoration: none;
  &:hover {
    color: #45a049;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  color: #333;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    color: #4CAF50;
  }
`;

// Style pour le lien externe du blog
const ExternalLink = styled.a`
  color: #333;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    color: #4CAF50;
  }
`;

const Navigation = () => {
  return (
    <Nav>
      <NavContainer>
        <Logo to="/">Bien Manger Vivre Mieux</Logo>
        <NavLinks>
          <NavLink to="/">Accueil</NavLink>
          <NavLink to="/recettes">Recettes</NavLink>
          <NavLink to="/planificateur">Planificateur de Repas</NavLink>
          <NavLink to="/suivi">Suivi Alimentaire</NavLink>
          <ExternalLink href="/blog">Blog</ExternalLink>
          <NavLink to="/contact">Contact</NavLink>
        </NavLinks>
      </NavContainer>
    </Nav>
  );
};

export default Navigation;
