export const nutritionTips = {
  general: [
    {
      id: 1,
      title: "Hydratation",
      content: "Buvez au moins 2L d'eau par jour pour maintenir une bonne hydratation. L'eau aide à la digestion, au transport des nutriments et à l'élimination des toxines.",
      icon: "💧"
    },
    {
      id: 2,
      title: "Portions",
      content: "Utilisez la règle de l'assiette : 1/2 légumes, 1/4 protéines, 1/4 glucides complexes.",
      icon: "🍽️"
    },
    {
      id: 3,
      title: "Variété",
      content: "Diversifiez votre alimentation pour obtenir tous les nutriments nécessaires. Mangez arc-en-ciel !",
      icon: "🌈"
    }
  ],
  mealSpecific: {
    breakfast: [
      {
        id: 1,
        title: "Petit-déjeuner Équilibré",
        content: "Incluez des protéines, des fibres et des fruits frais pour un début de journée énergique.",
        examples: ["Porridge aux fruits", "Œufs et avocat", "Smoothie protéiné"]
      },
      {
        id: 2,
        title: "Éviter le Sucre Raffiné",
        content: "Préférez les sucres naturels des fruits et du miel aux sucres raffinés.",
        alternatives: ["Fruits frais", "Miel", "Sirop d'érable"]
      }
    ],
    lunch: [
      {
        id: 1,
        title: "Repas Complet",
        content: "Équilibrez votre assiette avec des légumes, des protéines maigres et des glucides complexes.",
        examples: ["Quinoa aux légumes", "Salade composée", "Buddha bowl"]
      }
    ],
    dinner: [
      {
        id: 1,
        title: "Repas Léger",
        content: "Privilégiez un dîner léger, riche en légumes et protéines, pauvre en glucides.",
        examples: ["Soupe de légumes", "Poisson grillé", "Salade protéinée"]
      }
    ]
  },
  seasonalTips: {
    spring: [
      {
        id: 1,
        title: "Légumes de Printemps",
        content: "Profitez des asperges, petits pois et artichauts de saison.",
        benefits: ["Riches en fibres", "Vitamines fraîches", "Minéraux essentiels"]
      }
    ],
    summer: [
      {
        id: 1,
        title: "Fruits d'Été",
        content: "Consommez des fruits frais comme les pêches, abricots et melons.",
        benefits: ["Hydratation naturelle", "Vitamines", "Antioxydants"]
      }
    ],
    autumn: [
      {
        id: 1,
        title: "Légumes d'Automne",
        content: "Intégrez les courges, potirons et champignons dans vos repas.",
        benefits: ["Fibres", "Bêta-carotène", "Minéraux"]
      }
    ],
    winter: [
      {
        id: 1,
        title: "Légumes d'Hiver",
        content: "Consommez des légumes racines comme les carottes, panais et betteraves.",
        benefits: ["Vitamines", "Minéraux", "Fibres"]
      }
    ]
  },
  dietaryRestrictions: {
    vegetarian: [
      {
        id: 1,
        title: "Sources de Protéines Végétales",
        content: "Assurez-vous d'inclure légumineuses, tofu, tempeh et quinoa dans votre alimentation.",
        examples: ["Lentilles", "Pois chiches", "Haricots rouges"]
      }
    ],
    glutenFree: [
      {
        id: 1,
        title: "Alternatives Sans Gluten",
        content: "Remplacez les céréales contenant du gluten par du riz, quinoa, sarrasin ou millet.",
        alternatives: ["Riz", "Quinoa", "Sarrasin"]
      }
    ],
    dairyFree: [
      {
        id: 1,
        title: "Alternatives aux Produits Laitiers",
        content: "Optez pour des laits végétaux enrichis en calcium et des alternatives aux produits laitiers.",
        alternatives: ["Lait d'amande", "Lait de soja", "Yaourt de coco"]
      }
    ]
  }
};
