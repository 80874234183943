import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const FooterContainer = styled.footer`
  background-color: #2c3e50;
  color: #fff;
  padding: 3rem 2rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FooterTitle = styled.h3`
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

const FooterLink = styled(Link)`
  color: #ecf0f1;
  text-decoration: none;
  transition: color 0.3s ease;
  
  &:hover {
    color: #4CAF50;
  }
`;

const ExternalLink = styled.a`
  color: #ecf0f1;
  text-decoration: none;
  transition: color 0.3s ease;
  
  &:hover {
    color: #4CAF50;
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #ecf0f1;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterTitle>Bien Manger Vivre Mieux</FooterTitle>
          <FooterLink to="/">Accueil</FooterLink>
          <FooterLink to="/recettes">Recettes</FooterLink>
          <FooterLink to="/planificateur">Planificateur de Repas</FooterLink>
          <FooterLink to="/suivi">Suivi Alimentaire</FooterLink>
        </FooterSection>
        
        <FooterSection>
          <FooterTitle>Liens Utiles</FooterTitle>
          <ExternalLink href="https://bienmanger-vivremieux.fr/blog/" target="_blank" rel="noopener noreferrer">
            Blog
          </ExternalLink>
          <ExternalLink href="https://socy.fr" target="_blank" rel="noopener noreferrer">
            Socy.fr
          </ExternalLink>
          <FooterLink to="/mentions-legales">Mentions Légales</FooterLink>
        </FooterSection>
        
        <FooterSection>
          <FooterTitle>Contact</FooterTitle>
          <ExternalLink href="mailto:contact@socy.fr">contact@socy.fr</ExternalLink>
          <FooterLink to="/contact">Formulaire de Contact</FooterLink>
        </FooterSection>
      </FooterContent>
      
      <Copyright>
        © {new Date().getFullYear()} Bien Manger Vivre Mieux. Tous droits réservés.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
