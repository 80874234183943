import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FaMinus, FaPlus, FaUsers } from 'react-icons/fa';

const CalculatorContainer = styled.div`
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 12px;
  margin: 1.5rem 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const Title = styled.h3`
  margin: 0;
  color: #2c3e50;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ServingControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  background: white;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const ControlButton = styled(motion.button)`
  background: ${props => props.disabled ? '#e0e0e0' : '#3498db'};
  color: white;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  font-size: 1rem;

  &:hover:not(:disabled) {
    background: #2980b9;
  }
`;

const ServingCount = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
  color: #2c3e50;
  min-width: 3rem;
  text-align: center;
`;

const IngredientsList = styled.div`
  display: grid;
  gap: 1rem;
`;

const IngredientItem = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const IngredientName = styled.span`
  color: #2c3e50;
`;

const IngredientAmount = styled.span`
  color: #666;
  font-weight: 500;
`;

const calculateAmount = (baseAmount, baseServings, currentServings) => {
  return (baseAmount * currentServings) / baseServings;
};

const formatAmount = (amount, unit) => {
  if (amount >= 1000) {
    return `${(amount / 1000).toFixed(1)} ${unit === 'g' ? 'kg' : 'L'}`;
  }
  return `${Math.round(amount * 10) / 10} ${unit}`;
};

const ServingCalculator = ({ ingredients, defaultServings = 4 }) => {
  const [servings, setServings] = useState(defaultServings);

  if (!ingredients || !Array.isArray(ingredients)) {
    return null;
  }

  const handleDecrease = () => {
    if (servings > 1) {
      setServings(prev => prev - 1);
    }
  };

  const handleIncrease = () => {
    if (servings < 12) {
      setServings(prev => prev + 1);
    }
  };

  return (
    <CalculatorContainer>
      <Header>
        <Title>
          <FaUsers />
          Portions
        </Title>
        <ServingControls>
          <ControlButton
            onClick={handleDecrease}
            disabled={servings <= 1}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaMinus />
          </ControlButton>
          <ServingCount>{servings}</ServingCount>
          <ControlButton
            onClick={handleIncrease}
            disabled={servings >= 12}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaPlus />
          </ControlButton>
        </ServingControls>
      </Header>

      <IngredientsList>
        {ingredients.map((ingredient, index) => (
          <IngredientItem
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <IngredientName>{ingredient.name}</IngredientName>
            <IngredientAmount>
              {formatAmount(
                calculateAmount(ingredient.amount, defaultServings, servings),
                ingredient.unit
              )}
            </IngredientAmount>
          </IngredientItem>
        ))}
      </IngredientsList>
    </CalculatorContainer>
  );
};

export default ServingCalculator;
